 
  

  [data-theme="dark"] {
   
    --background-color: #393e46;
    --color:rgb(247, 197, 35);
  }
  
  [data-theme="light"] {
 
    --background-color: #f4f4f4;
    --link-color: rgb(5, 5, 5);
    --color:rgb(0, 0, 0);
  }
  
  
.container-fluid {
    background-color:var( --background-color);
 
  }



  .text{
    color: var(--color);
  }

  p {
    color: var(--link-color);
  }
 

 

a{
    color: var(--link-color);  
}
 
  /* Custom Dark Mode Toggle Element */
  
  .toggle-theme-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .toggle-theme-wrapper span {
    font-size: 28px;
    margin: 0 4px;
  }
  
  .toggle-theme {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
  }
  
  .toggle-theme input {
    display: none;
  }
  
  .slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
  }
  
  .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  input:checked + .slider {
    background-color: cornflowerblue;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  